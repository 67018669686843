(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('institutional.features.card', [])
    .component('institutionalFeaturesCard', {
      controller: Controller,
      templateUrl: 'root/institutional/_components/features/card/card-features-institutional.comp.html',
      bindings: {
        title: '@',
        body: '@',
        image: '@'
      }
    });
}());
