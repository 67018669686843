(function () {
  'use strict';
  class Controller {
    constructor() {
    }
  }
  angular
    .module('institutional.pricing', [
      'institutional.pricing.title',
      'institutional.pricing.card'
    ])
    .component('institutionalPricing', {
      controller: Controller,
      templateUrl: 'root/institutional/_components/pricing/pricing-institutional.comp.html'
    });
}());
