(function () {
  'use strict';
  class Controller {}
  angular
    .module('institutional.about.slider', [])
    .component('institutionalAboutSlider', {
      controller: Controller,
      templateUrl: 'root/institutional/_components/about/slider/slider-about-institutional.comp.html'
    });
}());
