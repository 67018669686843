(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('institutional', [
      'institutional.header',
      'institutional.about',
      'institutional.features',
      'institutional.pricing',
      'institutional.cta',
      'institutional.footer'
    ])
    .component('institutionalEl', {
      controller: Controller,
      templateUrl: 'root/institutional/institutional.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('institutional', {
          abstract: false,
          url: '/institutional',
          template: '<institutional-el/>'
        });
    });
}());
