(function () {
  'use strict';
  angular
    .module('app.firedux', [
      'ng-webcat'
    ])
    .run(run);
  function run($firedux, $location) {
    const isProduction = $location.protocol() === 'https' || true;
    $firedux.init({
      config: {
        apiKey: 'AIzaSyDyu31kKT-KQ-EjDflczcf03AxP_-g3gq8',
        authDomain: 'intelligere-94e9b.firebaseapp.com',
        databaseURL: 'https://intelligere-94e9b.firebaseio.com',
        storageBucket: 'intelligere-94e9b.appspot.com'
      },
      base: isProduction ? 'production' : 'staging'
    });
  }
}());
