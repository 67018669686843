(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('landing.features.card', [])
    .component('landingFeaturesCard', {
      controller: Controller,
      templateUrl: 'root/landing/_components/features/card/card-features-landing.comp.html',
      bindings: {
        title: '@',
        body: '@',
        image: '@'
      }
    });
}());
