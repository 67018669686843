(function () {
  'use strict';
  class Controller {
    constructor() {
      this.init = Date.now();
    }
    $then(article) {
      let mins = (Date.now() - this.init) / (1000 * 60),
          length = article.split(' ').length,
          wpm = length / mins;
      this.then({
        $wpm: wpm
      });
    }
  }
  angular
    .module('article.content', [])
    .component('articleContent', {
      controller: Controller,
      templateUrl: 'root/_modules/article/content/content-article.comp.html',
      bindings: {
        article: '<',
        then: '&',
        catch: '&'
      }
    });
}());
