(function () {
  'use strict';
  class Controller {
    constructor($interval) {
      this.$interval = $interval;
    }
    $onInit() {
      this.getData();
      this.createInterval();
    }
    $onDestroy() {
      this.destroyInterval();
    }
    createInterval(interval = 5000) {
      this.tick();
      this.interval = this.$interval(() => {
        this.tick();
      }, interval);
    }
    destroyInterval() {
      this.$interval.cancel(this.interval);
    }
    getData(wpm = 700) {
      // https://www.forbes.com/sites/brettnelson/2012/06/04/do-you-read-fast-enough-to-be-successful/#6575f8a8462e
      // http://www.europapress.es/cultura/exposiciones-00131/noticia-cuanto-tardarias-leer-toda-saga-juego-tronos-20140918102233.html
      this.current = 0;
      this.wpm = wpm;
      this.books = [
        {
          title: 'toda la saga de Game of Thrones',
          words: 1770000
        },
        {
          title: 'toda la saga de Harry Potter',
          words: 1084170
        },
        {
          title: 'la biblia',
          words: 788280
        },
        {
          title: 'toda la saga de Los juegos del hambre',
          words: 301583
        }
      ];
    }
    tick() {
      this.bookTitle = this.books[this.current].title;
      this.bookWords = this.books[this.current].words;
      this.bookTime = this.readableTime(this.books[this.current].words / this.wpm);
      this.current++;
      if (this.current === this.books.length) {
        this.current = 0;
      }
    }
    readableTime(minutes) {
      let seconds = minutes * 60,
          numhours = Math.ceil(seconds / 3600);
          // numminutes = Math.floor(seconds % 31536000 % 86400 % 3600 / 60);
      return numhours + ' horas';
    }
  }
  angular
    .module('institutional.header.slider', [])
    .component('institutionalHeaderSlider', {
      controller: Controller,
      templateUrl: 'root/institutional/_components/header/slider/slider-header-institutional.comp.html'
    });
}());
