(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('landing.features.title', [])
    .component('landingFeaturesTitle', {
      controller: Controller,
      templateUrl: 'root/landing/_components/features/title/title-features-landing.comp.html'
    });
}());
