(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('institutional.about.feature', [])
    .component('institutionalAboutFeature', {
      controller: Controller,
      templateUrl: 'root/institutional/_components/about/feature/feature-about-institutional.comp.html',
      bindings: {
        icon: '<',
        title: '@',
        body: '@'
      }
    });
}());
