(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('landing.about.feature', [])
    .component('landingAboutFeature', {
      controller: Controller,
      templateUrl: 'root/landing/_components/about/feature/feature-about-landing.comp.html',
      bindings: {
        icon: '<',
        title: '@',
        body: '@'
      }
    });
}());
