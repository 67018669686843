(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('institutional.about', [
      'institutional.about.feature',
      'institutional.about.slider'
    ])
    .component('institutionalAbout', {
      controller: Controller,
      templateUrl: 'root/institutional/_components/about/about-institutional.comp.html'
    });
}());
