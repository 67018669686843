(function () {
  'use strict';
  class Controller {
    constructor($state) {
      this.$state = $state;
    }
  }
  angular
    .module('nav', [])
    .component('navComp', {
      controller: Controller,
      templateUrl: 'root/_components/nav/nav.comp.html',
      bindings: {
        theme: '@'
      }
    });
}());
