(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('institutional.cta', [])
    .component('institutionalCta', {
      controller: Controller,
      templateUrl: 'root/institutional/_components/cta/cta-institutional.comp.html'
    });
}());
