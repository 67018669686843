(function () {
  'use strict';
  class Controller {
    constructor($window) {
      this._ = $window._;
    }
    $onInit() {
      this.total = this.total || 0.5;
      this.amount = (this.cycles || 4) * 5;
      this.dictionary = this._(this.words).sample(this.amount / this.total);
    }
    $processWords(words) {
      let goodWords = this.parseWords(words),
          sample = this._(goodWords).sample(this.amount / 2);
      this.$dictionary = this._.shuffle(this._.union(this.dictionary, sample));
    }
    $length(object) {
      return this._(object).size();
    }
    $verify(chosen) {
      let chosenArray = [];
      angular.forEach(chosen, (tru, word) => {
        chosenArray.push(word);
      });
      this.then({
        $data: this._.intersection(this.dictionary, chosenArray).length / this.dictionary.length
      });
    }
    parseWords(words) {
      let returnable = [];
      this._(words).map(word => {
        if (word.length >= 3 && word.length <= 7) {
          returnable.push(word);
        }
      });
      return returnable;
    }
  }
  angular
    .module('modules.triangles.test', [])
    .component('trianglesTest', {
      controller: Controller,
      templateUrl: 'root/_modules/triangles/test/test-triangles.comp.html',
      bindings: {
        cycles: '<',
        words: '<',
        total: '<',
        then: '&'
      }
    });
}());
