(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('landing.pricing.title', [])
    .component('landingPricingTitle', {
      controller: Controller,
      templateUrl: 'root/landing/_components/pricing/title/title-pricing-landing.comp.html'
    });
}());
