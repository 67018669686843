(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('modules.article', [
      'article.intro',
      'article.content',
      'article.test',
      'article.summary'
    ])
    .component('articleModule', {
      controller: Controller,
      templateUrl: 'root/_modules/article/article.comp.html',
      bindings: {
        questions: '<',
        then: '&',
        catch: '&'
      }
    });
}());
