(function () {
  'use strict';
  class Controller {
    constructor($mdColors) {
      this.startColor = $mdColors.getThemeColor('grey-primary-50');
      this.endColor = $mdColors.getThemeColor('cyan-primary-50');
      this.benefits = [
        'Desarrolla la memoria a corto, mediano y largo plazo',
        'Aumenta tus niveles de concentración',
        'Aumenta tu productividad',
        'Ahorra tiempo',
        'Aprende más rápido',
        'Mejora tu vocabulario',
        'Impulsa tu carrera',
        'Gana más dinero'
      ];
    }
  }
  angular
    .module('landing.features', [
      'landing.features.title',
      'landing.features.hero',
      'landing.features.card'
    ])
    .component('landingFeatures', {
      controller: Controller,
      templateUrl: 'root/landing/_components/features/features-landing.comp.html'
    });
}());
