(function () {
  'use strict';

  angular
    .module('public', [
      'app.routes',
      'app.firedux',
      'app.themes',
      'app.scroll',
      'app.root',
      'app.modules'
    ]);
}());
