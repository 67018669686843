(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('landing.about', [
      'landing.about.feature',
      'landing.about.slider'
    ])
    .component('landingAbout', {
      controller: Controller,
      templateUrl: 'root/landing/_components/about/about-landing.comp.html'
    });
}());
