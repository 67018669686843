(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('institutional.pricing.card', [])
    .component('institutionalPricingCard', {
      controller: Controller,
      templateUrl: 'root/institutional/_components/pricing/card/card-pricing-institutional.comp.html'
    });
}());
