(function () {
  'use strict';
  function config($mdThemingProvider) {
    $mdThemingProvider.theme('default')
      .primaryPalette('cyan', {
        default: '900'
      });
    $mdThemingProvider.theme('institutional')
      .primaryPalette('brown', {
        default: '900'
      });
    $mdThemingProvider.theme('bright')
      .primaryPalette('cyan', {
        default: '500'
      });
    $mdThemingProvider.theme('grey')
      .primaryPalette('grey', {
        default: '500'
      });
    $mdThemingProvider.theme('cyan')
      .primaryPalette('cyan', {
        default: '600'
      });
    $mdThemingProvider.theme('lightBlue')
      .primaryPalette('light-blue', {
        default: '500'
      });
    $mdThemingProvider.theme('blueGrey')
      .primaryPalette('blue-grey', {
        default: '500'
      });
  }
  angular
    .module('app.themes', [])
    .config(config);
}());
