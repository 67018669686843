(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('modules.triangles', [
      'modules.triangles.intro',
      'modules.triangles.triangle',
      'modules.triangles.test',
      'modules.triangles.summary'
    ])
    .component('trianglesModule', {
      controller: Controller,
      templateUrl: 'root/_modules/triangles/triangles.comp.html',
      bindings: {
        period: '<',
        cycles: '<',
        total: '<'
      }
    });
}());
