(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('modules.triangles.summary', [])
    .component('trianglesSummary', {
      controller: Controller,
      templateUrl: 'root/_modules/triangles/summary/summary-triangles.comp.html',
      bindings: {
        result: '<',
        then: '&'
      }
    });
}());
