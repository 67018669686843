(function () {
  'use strict';
  angular
    .module('app.root', [
      // Routes
      'landing',
      'institutional',
      // Components
      'nav'
    ]);
}());
