(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('institutional.header', [
      'institutional.header.slider'
    ])
    .component('institutionalHeader', {
      controller: Controller,
      templateUrl: 'root/institutional/_components/header/header-institutional.comp.html'
    });
}());
