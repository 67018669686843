(function () {
  'use strict';
  class Controller {
    constructor($mdColors) {
      this.startColor = $mdColors.getThemeColor('grey-primary-50');
      this.endColor = $mdColors.getThemeColor('institutional-primary-50');
    }
  }
  angular
    .module('institutional.features', [
      'institutional.features.title',
      'institutional.features.hero',
      'institutional.features.card'
    ])
    .component('institutionalFeatures', {
      controller: Controller,
      templateUrl: 'root/institutional/_components/features/features-institutional.comp.html'
    });
}());
