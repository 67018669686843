(function () {
  'use strict';
  function config(snSkrollrProvider) {
    snSkrollrProvider.config = {
      smoothScrolling: false,
      forceHeight: false
    };
    snSkrollrProvider.disableMobile = true;
  }
  function run(snSkrollr) {
    snSkrollr.init();
  }
  angular
    .module('app.scroll', [
      'sn.skrollr',
      'skroll.appear'
    ])
    .config(config)
    .run(run);
}());
