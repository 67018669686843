(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('article.summary', [])
    .component('articleSummary', {
      controller: Controller,
      templateUrl: 'root/_modules/article/summary/summary-article.comp.html',
      bindings: {
        wpm: '<',
        then: '&',
        catch: '&'
      }
    });
}());
