(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('landing.features.hero', [])
    .component('landingFeaturesHero', {
      controller: Controller,
      templateUrl: 'root/landing/_components/features/hero/hero-features-landing.comp.html',
      bindings: {
        inverted: '<',
        title: '@',
        body: '@',
        image: '@',
        module: '@',
        largeImage: '@'
      }
    });
}());
