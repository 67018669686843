(function () {
  'use strict';
  class Controller {
    constructor($window, $interval) {
      this._ = $window._;
      this.$interval = $interval;
      this.$words = ['', '', '', '', ''];
    }
    $processWords(words = {}) {
      let wordMap = [];
      this._(words).map(word => {
        if (!wordMap[word.length]) {
          wordMap[word.length] = [];
        }
        wordMap[word.length].push(word);
      });
      this.$start(wordMap);
    }
    $start(wordMap) {
      this.$cycles = [];
      this.$currentWord = 0;
      this.$currentCycle = 0;
      for (let i = 0; i < (this.cycles || 4); i++) {
        this.$cycles.push(this.getSample(wordMap));
      }
      this.interval = this.$interval(() => {
        this.addWord();
      }, this.period || 1000);
    }
    getSample(wordMap) {
      let samples = [];
      for (let i = 3; i <= 9; i++) {
        if (i !== 4 && i !== 6) {
          samples.push(this._(wordMap[i]).sample());
        }
      }
      return samples;
    }
    addWord() {
      this.$currentWord++;
      if (this.$currentWord > 5) {
        this.$currentWord = 0;
        this.$currentCycle++;
      }
      if (this.$currentCycle >= (this.cycles || 4)) {
        this.$interval.cancel(this.interval);
        this.then({
          $words: this._(this.$cycles).flatten()
        });
      }
    }
  }
  angular
    .module('modules.triangles.triangle', [])
    .component('trianglesTriangle', {
      controller: Controller,
      templateUrl: 'root/_modules/triangles/triangle/triangle-triangles.comp.html',
      bindings: {
        period: '<',
        cycles: '<',
        then: '&'
      }
    });
}());
