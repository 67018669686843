(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('landing.footer', [])
    .component('landingFooter', {
      controller: Controller,
      templateUrl: 'root/landing/_components/footer/footer-landing.comp.html'
    });
}());
