(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('landing', [
      'landing.header',
      'landing.about',
      'landing.features',
      'landing.pricing',
      'landing.cta',
      'landing.footer'
    ])
    .component('landingEl', {
      controller: Controller,
      templateUrl: 'root/landing/landing.route.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('landing', {
          abstract: false,
          url: '/',
          template: '<landing-el/>'
        });
    });
}());
