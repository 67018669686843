(function () {
  'use strict';
  class Controller {
    constructor() {}
    $rand(max) {
      return Math.floor(Math.random() * max);
    }
  }
  angular
    .module('article.intro', [])
    .component('articleIntro', {
      controller: Controller,
      templateUrl: 'root/_modules/article/intro/intro-article.comp.html',
      bindings: {
        then: '&',
        catch: '&'
      }
    });
}());
