(function () {
  'use strict';
  class Controller {
    constructor() {
    }
  }
  angular
    .module('landing.pricing', [
      'landing.pricing.title',
      'landing.pricing.card'
    ])
    .component('landingPricing', {
      controller: Controller,
      templateUrl: 'root/landing/_components/pricing/pricing-landing.comp.html'
    });
}());
