(function () {
  'use strict';
  angular
    .module('skroll.appear', [])
    .directive('skrollAppear', () => {
      return {
        templateUrl: 'root/_components/skroll-appear/skroll-appear.comp.html',
        transclude: true,
        replace: true
      };
    });
}());
