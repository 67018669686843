(function () {
  'use strict';
  class Controller {
    constructor() {}
  }
  angular
    .module('landing.header', [
      'landing.header.slider'
    ])
    .component('landingHeader', {
      controller: Controller,
      templateUrl: 'root/landing/_components/header/header-landing.comp.html'
    });
}());
